<template>
  <div>
    <v-row>
      <v-col>
        <div class="d-flex justify-center align-center">
          <v-progress-circular
            :rotate="-90"
            :size="150"
            :width="20"
            :value="value"
            color="primary"
          >
            <h2>{{ value }}%</h2>
          </v-progress-circular>
          <div class="ml-5">
            Armazenamento

            <h5 class="mt-0">{{ totalSpaceUsed }} Usado de {{ totalSpace }}</h5>
          </div>
        </div>
      </v-col>
      <v-col>
        <v-data-table
          disable-sort
          :items-per-page="-1"
          hide-default-footer
          :headers="headers"
          :items="totals"
        >
          <template v-slot:[`item.type`]="{ item }">
            {{ filesTypes[item.type] }}
          </template>
          <template v-slot:[`item.total_size`]="{ item }">
            {{ $format.bytesToSize(item.total_size) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totals: [],
      filesTypes: {
        "application/pdf": "PDF",
        "image/svg+xml": "SVG",
        "image/jpeg": "JPEG",
        "image/png": "PNG",
        "image/gif": "GIF",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "DOCX",
        "application/msword": "DOC",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "XLSX",
        "application/vnd.ms-excel": "XLS",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          "PPTX",
        "application/vnd.ms-powerpoint": "PPT",
        "application/zip": "ZIP",
        "application/x-rar-compressed": "RAR",
        "application/x-7z-compressed": "7Z",
        "application/octet-stream": "OUTRO",
      },
      headers: [
        {
          text: "Tipo",
          value: "type",
          align: "left",
        },
        {
          text: "Tamanho",
          value: "total_size",
          align: "left",
        },
        {
          text: "Quantidade",
          value: "total_files",
          align: "center",
        },
      ],
    };
  },

  created() {
    this.select();
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },
    totalSpace() {
      return this.$format.bytesToSize(this.company.storage_space);
    },
    totalSpaceUsed() {
      return this.$format.bytesToSize(this.company.storage_space_used);
    },

    value() {
      return Math.round(
        (this.company.storage_space_used * 100) / this.company.storage_space
      );
    },
  },

  methods: {
    select() {
      this.$loading.start();
      this.$http
        .index("company/files-settings")
        .then((response) => {
          this.totals = response.totals;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>